import React from "react";
import "./Corrections.scss";
import ConfirmationModal from "../../components/ConfirmationModal";

import { AuthContext } from '../../context/AuthContext';
import { firestore } from "../../context/FirebaseConfig";

import { Link } from "react-router-dom"
import { deleteDoc, updateDoc, collection, doc, where, query, orderBy, onSnapshot } from "firebase/firestore";
import { format, subHours } from "date-fns";

// Importing icons
import back_icon from '../../assets/icons/back_small.png';
import trash_icon from '../../assets/icons/trash.png';
import edit_icon from '../../assets/icons/edit.png';
import close_icon from '../../assets/icons/close.svg';
import accept_icon from '../../assets/icons/checkmark.png';

export default function Corrections() {
    const { currentUser } = React.useContext(AuthContext);
    const [ lastDocuments, setLastDocuments ] = React.useState([]);
    const [ settings, setSettings ] = React.useState({});
    const [ confirmDeleteDocId, setConfirmDeleteDocId ] = React.useState(-1);
    const [ editField, setEditField ] = React.useState(null);
    const [ editFieldContent, setEditFieldContent ] = React.useState({"supplier":"", "field":""});

    React.useEffect(() => {
        subscribeToDocuments();
        subscribeToBaleFeedSettings();
    }, []);

    function subscribeToDocuments() {
        const q = query(collection(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id, "bale_feed_timestamps"),
                where("start_timestamp", ">=", subHours(new Date(), 24)), orderBy("start_timestamp", "desc"));
        onSnapshot(q, (snapshot) => {
            const documentsNew = {};
            snapshot.forEach((docSnapshot) => {
                documentsNew[docSnapshot.id] = docSnapshot.data();
            })
            setLastDocuments(documentsNew);
        });
    }

    function subscribeToBaleFeedSettings() {
        /* Subscribe to document containing settings such as bale-feed-workers names */
        const q = doc(firestore, "clients", currentUser.company.company, "client_settings", "bale_feed_settings");
        onSnapshot(q, (snapshot) => {
            if (snapshot.empty) {
                return;
            }
            setSettings(snapshot.data())
        });
    }

    function handleDeleteDocument() {
        const q = doc(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id, "bale_feed_timestamps", confirmDeleteDocId);
        deleteDoc(q);
        setConfirmDeleteDocId(-1);
    }

    function handleEditField(id) {
        setEditFieldContent({
            "supplier": lastDocuments[id].supplier,
            "field": lastDocuments[id].field,
        })
        setEditField(id);
    }

    function handleAcceptEdit() {
        const q = doc(firestore, "clients", currentUser.company.company, "devices", currentUser.device_id, "bale_feed_timestamps", editField);
        updateDoc(q, editFieldContent);
        setEditField(null);
    }

    return (
        <div className="corrections">
            <div className="documents-list">
                {Object.keys(lastDocuments).map((id) => {
                    let supplierName = lastDocuments[id].supplier;
                    if (settings?.suppliers && lastDocuments[id].supplier in settings.suppliers) {
                        supplierName = `${settings.suppliers[lastDocuments[id].supplier].id} ${settings.suppliers[lastDocuments[id].supplier].name}`;
                    }

                    return <div key={id} className="document-container">
                        <div className="timestamp-container">
                            <p className="date-string">{format(lastDocuments[id].start_timestamp.toDate(), "dd. MMM yyyy")}</p>
                            <p className="time-string">{format(lastDocuments[id].start_timestamp.toDate(), "HH:mm:ss")} - 
                                {"end_timestamp" in lastDocuments[id] ? format(lastDocuments[id].end_timestamp.toDate(), "HH:mm:ss") : "Jetzt"}</p>
                        </div>
                        {editField === id ? <>
                            <div className="info-container">
                                <select className="settings-select"
                                    value={editFieldContent.supplier}
                                    onChange={(e) => {setEditFieldContent(prev => {return {...prev, "supplier": e.target.value}})}}
                                >
                                    <option key={-1} value={""}></option>
                                    { settings?.suppliers && Object.keys(settings.suppliers).length > 0 && 
                                    Object.entries(settings.suppliers)
                                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                                    .map(([key, value]) => {
                                        return <option key={key} value={key}>{value?.id} {value?.name}</option>
                                    })}
                                </select>
                                <select className="settings-select"
                                    value={editFieldContent.field}
                                    onChange={(e) => {setEditFieldContent(prev => {return {...prev, "field": e.target.value}})}}
                                >
                                    <option key={-1} value={""}></option>
                                    { settings?.fields && Object.keys(settings.fields).length > 0 && 
                                    Object.entries(settings.fields)
                                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                                    .map(([key, value]) => {
                                        return <option key={key} value={key}>{value?.field}</option>
                                    })}
                                </select>
                            </div>
                            <button className="accept-button" onClick={handleAcceptEdit}> 
                                <img src={accept_icon}></img>
                            </button>
                            <button className="cancel-button" onClick={() => {setEditField(null)}}>
                                <img src={close_icon}></img>
                            </button>
                        </>:<>
                            <div className="info-container">
                                <p className="supplier-string">{supplierName}</p>
                                <p className="field-string">Feld {lastDocuments[id].field}</p>
                            </div>
                            <button className="edit-button" onClick={() => {handleEditField(id)}}> 
                                <img src={edit_icon}></img>
                            </button>
                            <button className="delete-button" onClick={() => setConfirmDeleteDocId(id)}>
                                <img src={trash_icon}></img>
                            </button>
                        </>}
                    </div>
                })}
            </div>
            <Link className="close-button" to="/feed-timestamping">
                <img src={back_icon}></img>
            </Link>

            {confirmDeleteDocId!==-1 && <ConfirmationModal 
                message={"Zeitstempel definitiv löschen?"}
                handleSubmit={() => handleDeleteDocument()}
                handleClose={() => setConfirmDeleteDocId(-1)}
            />}
        </div>
    )
}